import React, { Fragment } from 'react';

import { connect } from 'react-redux';

const VerticalNav = () => {
  return <Fragment>Navbar</Fragment>;
};

VerticalNav.propTypes = {};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, {})(VerticalNav);
