import React, { Fragment, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';

import { getMeta } from '../../actions/meta';

const Metadata = ({ meta: { meta, loadingMeta }, getMeta }) => {
  useEffect(() => {
    getMeta();
  }, [getMeta]);

  return !loadingMeta && meta.length > 0 ? (
    <Fragment>
      {meta.map((item) => {
        const keyToReturn = item.SK.split('-')
          .map((item) => {
            return item.replace(/\w\S*/g, function (txt) {
              return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
            });
          })
          .join(' ');
        const valueToReturn =
          item.SK == 'last-upload'
            ? new Date(item.value).toLocaleString('en-GB', {
                day: 'numeric',
                month: 'short',
                year: 'numeric',
                hour: 'numeric',
                minute: 'numeric',
                second: 'numeric',
              })
            : item.value;
        return (
          <Typography variant='body1'>
            {keyToReturn}: {valueToReturn}
          </Typography>
        );
      })}
    </Fragment>
  ) : (
    <Fragment></Fragment>
  );
};

Metadata.propTypes = {
  meta: PropTypes.object.isRequired,
  getMeta: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  meta: state.meta,
});

export default connect(mapStateToProps, { getMeta })(Metadata);
