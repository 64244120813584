import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />;
});

const AlertUi = ({ alert: { alert } }) => {
  const [open, setOpen] = useState(false);
  useEffect(() => {
    let openState =
      Object.keys(alert).length === 0 && alert.constructor === Object
        ? false
        : true;
    setOpen(openState);
  }, [alert]);
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };
  return (
    <Stack spacing={2} sx={{ width: '100%' }}>
      <Snackbar
        key={alert.id}
        open={open}
        autoHideDuration={alert.timeout * 1}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity={alert.alertType}>
          {alert.msg}
        </Alert>
      </Snackbar>
    </Stack>
  );
};

AlertUi.propTypes = {
  alert: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  alert: state.alert,
});

export default connect(mapStateToProps)(AlertUi);
