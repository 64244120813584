import axios from 'axios';
import { setAlert } from './alert';
import { GET_META } from './types';

export const getMeta = () => async (dispatch) => {
  try {
    const res = await axios.get(`${process.env.REACT_APP_URL_BACKEND}/meta`);
    const Items = res.data.Items;
    dispatch({
      type: GET_META,
      payload: Items,
    });
  } catch (error) {
    console.log(error);
    dispatch(setAlert('Problem on getting uploader meta', 'error', 5000));
  }
};
