import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { styled } from '@mui/material/styles';

import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import { uploadPdf } from '../../actions/files';

const Uploader = ({ uploadPdf }) => {
  const [formData, setFormData] = useState({
    selectedFile: null,
  });

  const [fileName, setFileName] = useState('Selected file: -');

  const { selectedFile } = formData;

  const onFileChange = (e) => {
    setFileName(`Selected file: ${e.target.files[0].name}`);
    setFormData({ ...formData, selectedFile: e.target.files[0] });
  };
  const onClick = (e) => {
    console.log(selectedFile);
    uploadPdf(selectedFile);
  };
  const Input = styled('input')({
    display: 'none',
  });
  return (
    <Fragment>
      <div className='form-group row text-muted'>
        <div className='offset-md-4 col-md-3'>
          <div className='form-group files'>
            <label htmlFor='outlined-button-file'>
              <Input
                accept='.pdf'
                id='outlined-button-file'
                multiple
                type='file'
                name='file'
                onChange={(e) => onFileChange(e)}
              />
              <Button variant='outlined' component='span'>
                Pilih File
              </Button>
            </label>
            <br />
            <Typography variant='caption' display='block' gutterBottom>
              {fileName}
            </Typography>
          </div>
          <Button
            color='primary'
            variant='contained'
            onClick={(e) => onClick(e)}
          >
            Upload & Konversi
          </Button>
        </div>
      </div>
    </Fragment>
  );
};

Uploader.propTypes = {
  uploadPdf: PropTypes.func.isRequired,
};

export default connect(null, { uploadPdf })(Uploader);
