import axios from 'axios';
import { setAlert } from './alert';
import slugify from 'slugify';

import setHeaderUploader from '../utils/setHeaderUploader';
// import config from '../config';

export const uploadPdf = (formData) => async (dispatch) => {
  try {
    dispatch(setAlert('Uploading file. ', 'info'));
    const newName = slugify(formData.name);
    const urlParams = {
      fileName: newName,
      fileType: formData.type,
    };
    const reqConfig = setHeaderUploader();
    const res = await axios.post(
      `${process.env.REACT_APP_URL_BACKEND}/url`,
      urlParams
    );
    const uploadResult = await axios.put(res.data.url, formData, reqConfig);
    console.log(uploadResult);
    dispatch(
      setAlert(
        'File berhasil diupload, link download akan dikirim lewat slack. ',
        'success',
        5000
      )
    );
  } catch (error) {
    console.log(error);
    dispatch(setAlert('Problem on uploading file', 'error', 5000));
  }
};
