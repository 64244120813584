import { GET_META } from '../actions/types';

const initialState = {
  meta: [],
  loadingMeta: true,
};

export default function meta(state = initialState, action) {
  switch (action.type) {
    case GET_META:
      return { ...state, meta: action.payload, loadingMeta: false };
    default:
      return state;
  }
}
