import React from 'react';
import { styled } from '@mui/material/styles';

import AppBar from '@mui/material/AppBar';
import Typography from '@mui/material/Typography';
import Toolbar from '@mui/material/Toolbar';

const AppBarCustom = styled(AppBar)(({ theme }) => ({
  zIndex: theme.zIndex.drawer + 1,
}));

const Header = (props) => {
  return (
    <AppBarCustom position='fixed'>
      <Toolbar>
        <Typography variant='h6' noWrap>
          {process.env.REACT_APP_TITLE}
        </Typography>
      </Toolbar>
    </AppBarCustom>
  );
};

export default Header;
