import React, { useEffect } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import { styled } from '@mui/material/styles';

import CssBaseline from '@mui/material/CssBaseline';
import Toolbar from '@mui/material/Toolbar';

import Header from './components/layout/Header';
import VerticalNav from './components/layout/VerticalNav';
import Alert from './components/elements/Alert';
import Dashboard from './components/pages/Dashboard';

import store from './store';

function PrivateRoute({ children }) {
  return children;
}

const Root = styled('div')(({ theme }) => ({
  display: 'flex',
}));

const MainCustom = styled('main')(({ theme }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
}));

const App = () => {
  useEffect(() => {}, []);
  return (
    <Provider store={store}>
      <Router>
        <Root>
          <CssBaseline />
          <Header />
          <VerticalNav />
          <MainCustom>
            <Toolbar />
            <Alert />
            <Routes>
              <Route
                path='/'
                element={
                  <PrivateRoute>
                    <Dashboard />
                  </PrivateRoute>
                }
              ></Route>
              <Route
                path='/dashboard'
                element={
                  <PrivateRoute>
                    <Dashboard />
                  </PrivateRoute>
                }
              ></Route>
            </Routes>
          </MainCustom>
        </Root>
      </Router>
    </Provider>
  );
};

export default App;
