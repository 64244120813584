import React from 'react';
import { connect } from 'react-redux';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';

import Uploader from './Uploader';
import Metadata from './Metadata';

const Dashboard = () => {
  return (
    <Card>
      <CardHeader
        title='Konversi PDF dari Jago ke CSV'
        subheader='Upload PDF dengan form di bawah ini, link download akan dikirim lewat Slack.'
      ></CardHeader>
      <CardContent>
        <Metadata></Metadata>
        <Uploader></Uploader>
      </CardContent>
    </Card>
  );
};

Dashboard.propTypes = {};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, {})(Dashboard);
